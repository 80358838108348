import React from 'react';
import Layout from '../components/Layout/Layout';
import { object } from 'prop-types';
//import Fade from 'react-reveal/Fade';

import WebConsulting from '../images/services/WebConsulting.inline.svg';
import WebDevelopment from '../images/services/WebDevelopment.inline.svg';
import WebMantainence from '../images/services/WebMantainence.inline.svg';

import WebConsultingImage from '../images/services/section1.png';
import WebDevelopmentImage from '../images/services/section2.png';
import WebMantainenceImage from '../images/services/section3.png';
import WebMantainenceImagelogo from '../images/services/QualifiedDevelopers-logo.png';
import WebMantainenceImageMain from '../images/services/QualifiedDevelopers-main.png';

const Services = ({ location }) => {

  return (
    <Layout className="services" location={location}>
      <div className="content">
        <div className="content-inner">
          <section className="section-service section-service-web-consulting">
            <div className="section-service--inner is-flex">
              <div className="service-content-summary">
                <div className="service-content-summary--inner">
                  <h2 className="is-hidden">Web Consulting</h2>
                  <WebConsulting />
                  <p>We can help you create your digital presence, improve your web platform or plan and implement privacy and accessibility regulations</p>
                  <a className="btn btn-big" href="/contact">Contact Us</a>
                </div>
              </div>
              <div className="service-content-media is-flex">
                <img src={WebConsultingImage} alt="" />
              </div>
            </div>
          </section>
          <section className="section-service section-service-web-development">
            <div className="section-service--inner is-flex">
              <div className="service-content-summary">
                <div className="service-content-summary--inner">
                  <WebDevelopment />
                  <h2 className="is-hidden">Web Development</h2>
                  <p>We use the power of open-source to develop web applications, corporate sites or enterprise-level CMS’s using Drupal, React and  Gatsby or NexyJS.</p>
                  <a className="btn btn-big" href="/contact">Contact Us</a>
                </div>
              </div>
              <div className="service-content-media is-flex">
              <img src={WebDevelopmentImage} alt="" />
              </div>
            </div>
          </section>
          <section className="section-service section-service-web-mantainence">
          <div className="section-service--inner">
            <div className="service-content-summary">
              <div className="service-content-summary--inner">
                <WebMantainence />
                <h2 className="is-hidden">Web Mantainence</h2>
                <p>We keep your site fresh and safe by developing new improvements and keeping the core up to date.</p>
              </div>
            </div>
            <div className="service-content-media">
              <img src={WebMantainenceImage} alt="" />
            </div>
          </div>
          </section>
          <section className="section-service highly-qualified-developers">
            <div className="highly-qualified-developers--inner is-flex">
              <div className="service-content-summary">
                <div className="service-content-summary--inner">
                  <img src={WebMantainenceImagelogo} alt="" />
                  <h4>Scale your team with our</h4>
                  <h2>Highly Qualified Developers</h2>
                  <p>Rely on our staff augmentation services to outsource complete projects or simply to fill in resource gaps  with top-level developers that work in your time zone, so you can avoid delays and miscommunication.</p>
                  <a className="btn btn-big" href="/contact">Get Started Today</a>
                </div>
              </div>
              <div className="service-content-media">
                <img src={WebMantainenceImageMain} alt="" />
              </div>
            </div>
          </section>
          {/* <section className="section-service section-service-web-ai">
          <div className="section-service--inner is-flex">
            <div className="service-content-summary">
              <Fade left cascade distance="70px">
              <div className="service-content-summary--inner">
                <AI />
                <h2>Artificial Intelligence</h2>
                <p>We are pleased to announce to our friends and customers the launch our Artificial Intelligences & Machine Learning Consulting Services.  We now provide AI software development for our customers  who want to apply smarter solutions to their business problems.</p>
                <a className="btn btn-big" href="/blog/meet-our-new-ai-team">Meet Our AI Team</a>
              </div>
              </Fade>
            </div>
            <div className="service-content-media">
              <Fade right cascade distance="70px">
              <img src={AIImage} alt="" />
              </Fade>
            </div>
          </div>
          </section> */}
        </div>
      </div>
    </Layout>
  );
};

Services.propTypes = {
  location: object
};

export default Services;
